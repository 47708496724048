<template>
  <div class="about">
    <TopBar title="关于我们">
      <BackIcon></BackIcon>
    </TopBar>

    <v-container class="px-5">
      <TopInfo :title="aboutTitle" :img="aboutImgPath"></TopInfo>
      <div class="text-subtitle-1 grey--text">「Na+课表」为华东交通大学理工学院提供课表查询，电费查询等服务。</div>

      <div class="descs" v-for="(desc, i) in descs" :key="i">
        <div class="text-subtitle-1 mt-5">{{ desc.title }}</div>
        <div class="text-subtitle-1 grey--text">{{ desc.text }}</div>
      </div>

      <div class="text-subtitle-1 mt-5 grey--text">目前「Na+课表」的功能正在逐渐增加中，可能会有影响使用的BUG，如果你在使用中有任何问题，请联系support@sodion.net。</div>
    </v-container>
    <!-- 根据屏幕尺寸来判断是否需要fixed以及OverFlowY -->
    <OverFlowY v-if="!isLargeScreen"></OverFlowY>
    <v-footer :fixed="isLargeScreen"><v-row no-gutters justify="center"><v-subheader>©️2020 Na+课表</v-subheader></v-row></v-footer>
  </div>
</template>

<script>
import { isLargeScreenMixin, setChildViewMixin } from 'common/mixin'

export default {
  name: 'About',
  mixins: [isLargeScreenMixin, setChildViewMixin],
  data() {
    return {
      aboutImgPath: require('../../../assets/img/favicon.png'),
      aboutTitle: '「Na+课表」 1.0.1',
      descs: [
        {
          title: '数据来源：',
          text: '用户数据、课表数据、电费数据等来自学校官方教务系统。'
        },
        {
          title: '设计理念：',
          text: '「Na+」代表钠离子，钠是一种质地软、轻而极有伸展性的银白色的IA族的碱金属元素，所以设计初衷是为了拥有轻量、简洁的移动端教务系统。'
        }
      ]
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>